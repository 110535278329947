body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  line-height: 1.9;
  color: #8c8c8c;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", sans-serif;
  color: #000;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}

.text-black {
  color: #000;
}

.content {
  padding: 7rem 0;
}

.heading {
  font-size: 2.5rem;
  font-weight: 900;
}

.form-control {
  border: none;
  background: #f3f3f3;
}
.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
  background: #f3f3f3;
}

.col-form-label {
  color: #000;
}

.btn,
.form-control,
.custom-select {
  height: 50px;
}

.custom-select:active,
.custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.btn {
  border: none;
  border-radius: 4px !important;
}
.btn.btn-primary {
  background: rgb(67, 34, 144);
  color: #fff;
  padding: 15px 20px;
}
.btn:hover {
  color: #fff;
}
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.box {
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08);
}
.box h3 {
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;
}

label.error {
  font-size: 12px;
  color: red;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: #b90b0b;
}

#form-message-success {
  color: #55a44e;
  font-size: 18px;
  font-weight: bold;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

.message {
  width: 100%;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.message .success_message {
  font-style: 20px;
  color: green;
  position: absolute;
  transition: .3s;
  animation: button 0.3s linear;
  display: none;
}
.danger_message{
  font-style: 20px;
  color: red;
  position: absolute;
  transition: .3s;
  animation: button 0.3s linear;
  display: none;
}

@keyframes button {
  0% {
    transform: scale(0, 1);
  }
  0% {
    transform: scale(0, 5);
  }
  0% {
    transform: scale(1);
  }
}
