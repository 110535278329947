.heading-orange{
  color: orange;
  font-weight: bold;
  font-size: 27px;
}

.social-icons {
  display: flex;
}
.social-icons > li:hover {
  cursor: pointer;
}
.blink {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*Projects Css*/
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}
.project {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.project img {
  display: block;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.5s;
}
.p_layer {
  width: 100%;
  height: 0;
  background: linear-gradient(#ffffff, #383232d3);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  transition: height 0.5s;
}
.p_layer h3{
    font-weight: 500;
    color: orange;
    margin-bottom: 20px;
}
.p_layer p{
    color: black;
}
.project_btn{
    margin-top: 20px;
    color: #ff004f;
    text-decoration: none;
    font-size: 18px;
    border-radius: 50%;
    line-height: 40px;
    background: #fff;
    width: 40px;
    height: 40px;
    align-items: center;
}
.project:hover img{
    transform: scale(1.1);
}
.project:hover .p_layer{
    height: 100%;
}