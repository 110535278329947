.section-title{
    text-align: left;
    padding-bottom: 20px;
}
.section-title>h2{
    font-style: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    color: rgb(1, 2, 27);
}
.section-title h2::after{
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background-color: #34b7a7;
    bottom: 0;
    left: calc(50% -25px);
}
.section-title p{
    margin-bottom: 0;
}


.image-center{
    display: flex;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 5%;
    margin-right: 70%;
}

.about-category{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
}